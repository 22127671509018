/* App */
.App {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app-logo {
  display: flex;
  justify-content: left;
  align-items: left;
}

ul {
  list-style-type: none;
}

.topbar-wrapper {
  display: flex;
  align-items: center;
}

.supportLink {
  margin-left: 10px; 
}

/* SearchBar */
.search-title {
  background-color: #EFEFEF;
  margin-bottom: 3px;
  padding: 1px;
  text-align: left;
  color: #595969;
  font-weight: bold;
}

.search-content {
  margin-bottom: 3px;
  margin-top:3px;
  padding: 1px;
  text-align: left;
  color: #595969;
}

.dependent-dropdown {
  padding-left: 5px;
}

.submit-button {
  width: 10%;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.amplify-input {
  text-align: left;
}

.amplify-selectfield {
  text-align: left;
}

.amplify-input {
  margin-top: 8px;
  margin-left: 5px;
}

.dropdown-container{
  display: flex;
  align-items: center;
}

.amplify-radio__button {
  background-color: #245B95;
  border-color: #245B95;
}

.amplify-button:hover {
  background-color:  #245B95;;
}

/* Search result */
.result-items{
  text-align: left;
  color: #595969;
}

.amplify-table__th {
  text-align: left;
}

.amplify-table__body {
  text-align: left;
}

.amplify-table {
  margin-top: 50px;
}

.amplify-checkbox__icon {
  background-color: #245B95;
}

.result-container {
  margin-bottom: 10px;
  padding-top: 0px;
  overflow-x: auto;
}

.amplify-table__th{
  font-size: 10.5px; 
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
  color: #245B95;
}

.amplify-table__td{
  font-size: 10.5px; 

}

.amplify-message{
  width: 20%;
  margin: auto;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.file-icon {
  cursor: pointer;
}

.download-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-right: 10px;
}

.loader {
  padding-top: 20px;
}

.amplify-field-group__outer-end{
  padding-top: 8px;
}

.error-message {
  margin-top: 30px;
  margin-bottom: 30px;
}

.signout-button, .support-button, .help-button {
  color: #245B95;
  font-weight: normal;
}

.signout-button:hover, .support-button:hover, .help-button:hover {
  color: white;
}

.table-title{
  cursor: pointer;
}

.up-icon, .down-icon {
  padding-top: 2px;
  height: 15px;
  width: 15px;
}